/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-floating-promises */
import { inject } from '@angular/core';
import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { ImpersonationService } from 'psm5-web';
import { Observable, map } from 'rxjs';

export const AppStartupRouteGuard: CanActivateFn = (): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean => {
  const userService = inject(UserService);
  const router = inject(Router);
  const impersonationService = inject(ImpersonationService);
  return userService.loadUserInfo().pipe(
    map((userInfo) => {
      if (userInfo.canImpersonateOnStartup) {
        router.navigate(['impersonate']);
      } else if (!userInfo.hasPermissions) {
        console.warn('user has not any permissions');
        router.navigate(['error']);
      } else {
        if (impersonationService.impersonating) {
          impersonationService.clearImpersonation();
          location.href = '/calendar/slots';
        } else {
          router.navigate(['calendar/slots']);
        }
      }
      return true;
    })
  );
};

export const SilentRouteGuard: CanActivateFn = (): Observable<UrlTree | boolean> | Promise<UrlTree | boolean> | UrlTree | boolean => true;
