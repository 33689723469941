<flx-rule-validation [context]="saveValidationContext" displayMode="dialog"></flx-rule-validation>

<flx-loading-spinner
  [context]="[saveDocumentsValidationContext,saveValidationContext]"
  [baseZIndex]="1089"
  [autoZIndex]="false"
  [target]="spinnerEstate"
></flx-loading-spinner>

<form [formGroup]="form" novalidate autocomplete="off" *ngIf="vehiclePartParams$ | async as vehiclePartParams">
  <input type="hidden" id="vehiclePartId" formControlName="vehiclePartId" />
  <input type="hidden" id="tcn" formControlName="tcn" />
  <input type="hidden" id="vehiclePartType" formControlName="vehiclePartType" />

  <trkmgr-vehicle-information-section
    [form]="getForm('vehicleInformation')"
    [transporters]="transporters$ | async"
    [statuses]="statuses$ | async"
    [displayExpiryDate]="displayExpiryDate$ | async"
    [vehiclePartParams]="vehiclePartParams"
  ></trkmgr-vehicle-information-section>

  <div class="d-flex" *ngIf="vehiclePartType$ | async as vehiclePartType">
    <div class="section-title align-self-start p-2">{{ vehicleSectionTitleLabel$ | async | transloco }}</div>

    <ng-container [ngSwitch]="vehiclePartType">
      <ng-container *ngSwitchCase="'Chassis'">
        <trkmgr-chassis-section [form]="getForm('chassis')" [countries]="countries$ | async"></trkmgr-chassis-section>
      </ng-container>

      <ng-container *ngSwitchCase="'LNGContainer'">
        <trkmgr-lng-container-section
          [form]="getForm('lngcontainer')"
          [vehiclePartParams]="vehiclePartParams"
          [maxLoadingVolumePct]="maxLoadingVolumePct$ | async"
          [loadingSideOptions]="loadingSideOptions"
        ></trkmgr-lng-container-section>
      </ng-container>

      <ng-container *ngSwitchCase="'Tractor'">
        <trkmgr-tractor-section [form]="getForm('tractor')" [countries]="countries$ | async"></trkmgr-tractor-section>
      </ng-container>

      <ng-container *ngSwitchCase="'RoadTanker'">
        <trkmgr-road-tanker-section
          [form]="getForm('roadtanker')"
          [countries]="countries$ | async"
          [loadingSideOptions]="loadingSideOptions"
          [vehiclePartParams]="vehiclePartParams"
          [maxLoadingVolumePct]="maxLoadingVolumePct$ | async"
        ></trkmgr-road-tanker-section>
      </ng-container>
    </ng-container>
  </div>

  <trkmgr-vehicle-documents-section
    *ngIf="hasDocumentsSection"
    [isValidControl]="form.get('validDocumentsSection')"
    [currentDocuments]="vehicleDocuments$ | async"
    [mode]="mode$ | async"
    [vehiclePartId]="(formData$ | async)?.vehiclePartId"
    (openDocument)="openDocument($event)"
    (documentsChanged)="vehicleDocuments = $event"
  ></trkmgr-vehicle-documents-section>
</form>

<div class="section-footer section-footer-in-content">
  <div class="section-footer__commands">
    <button pButton class="p-button-tertiary" (click)="close()">{{ cancelButtonLabel$ | async | transloco }}</button>

    <ng-container [ngSwitch]="mode$ | async">
      <button
        *ngSwitchCase="'create'"
        pButton
        type="button"
        (click)="save()"
        [disabled]="form.invalid"
        transloco="app.modules.vehicles.components.vehicle-dialog.buttons.create"
      ></button>
      <button
        *ngSwitchCase="'edit'"
        pButton
        type="button"
        (click)="save(false)"
        [disabled]="form.invalid"
        transloco="app.modules.vehicles.components.vehicle-dialog.buttons.save"
      ></button>
      <button
        *ngSwitchCase="'editDocuments'"
        pButton
        type="button"
        (click)="save(false, true)"
        [disabled]="form.invalid"
        transloco="app.modules.vehicles.components.vehicle-dialog.buttons.save"
      ></button>
      <ng-container *ngSwitchCase="'view'">
        <ng-container *ngIf="mainAction$ | async as mainAction">
          <ng-container *ngIf="subActions$ | async; then withSubActions; else withoutSubActions"></ng-container>
          <ng-template #withSubActions>
            <p-splitButton
              [label]="mainAction.label | transloco"
              (onClick)="mainAction.command!()"
              [model]="(subActions$ | async)! | translateActions"
              [appendTo]="'body'"
            ></p-splitButton>
          </ng-template>
          <ng-template #withoutSubActions>
            <button pButton type="button" [label]="mainAction.label | transloco" (click)="mainAction.command!()"></button>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
