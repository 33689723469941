import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FlxDateService, toDate } from '@fluxys/common';
import { SelectItem } from 'primeng/api';
import { VehicleDocument } from '@shared/models/vehicle-document';

@Component({
  selector: 'trkmgr-add-vehicle-document',
  templateUrl: './add-vehicle-document.component.html',
})
export class AddVehicleDocumentsComponent {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() typeSelectItems: SelectItem<string>[] = [];
  @Input() title = '';
  @Input() maxEndDate: Date | undefined;
  @Output() readonly cancelClicked = new EventEmitter();
  @Output() readonly newFileAdded = new EventEmitter<VehicleDocument>();
  file: File | null = null;
  newFileName = '';

  addFile(event: File): void {
    this.newFileName = event.name;
    this.file = event;
    if (!this.form.get('id')?.value) {
      this.form.get('documentName')?.setValue(this.newFileName); // if no id, the line is new and documentName is empty. Add the new file name so we can save
    }
  }

  okClicked(): void {
    const doc: VehicleDocument = {
      id: this.form.get('id')?.value ?? 0,
      documentType: this.form.get('type')?.value,
      validFrom: toDate(this.form.get('validFrom')?.value),
      validTo: toDate(this.form.get('validTo')?.value),
      validated: this.form.get('validated')?.value,
      documentName: this.newFileName ? this.newFileName : this.form.get('documentName')?.value,
      document: this.file ?? undefined,
      isExpiring: false,
    };
    this.newFileAdded.emit(doc);
  }
}
