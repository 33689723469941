import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FlxDateService } from '@fluxys/common';
import { isNeitherNullNorUndefined } from '@fluxys/core';
import { DateTime } from 'luxon';
import { Calendar } from 'primeng/calendar';

@Component({
  selector: 'trkmgr-calendar-validTo',
  templateUrl: './calendar-validTo.component.html',
  styleUrls: ['./calendar-validTo.component.scss']
})
export class CalendarValidToComponent {
  @Input() form: UntypedFormGroup = new UntypedFormGroup({});
  @Input() maxEndDate: Date | undefined = new Date();
  @Input() hasLabel = true;
  @ViewChild('pcalendar', { static: false }) pcalendar?: Calendar;
  private tomorrow = this.dateService.getTomorrowDateTime();
  defaultDate = new Date();

  constructor(private readonly dateService: FlxDateService) {
    this.defaultDate.setFullYear(this.defaultDate.getFullYear() + 2);
  }

  onTomorrowClick(): void {
    this.form.get('validTo')?.setValue(this.tomorrow.toJSDate());
  }

  onInnerInput(): void {
    const numericDateString = (this.pcalendar?.inputfieldViewChild.nativeElement as HTMLInputElement | undefined)?.value.replace(/\W/gi, '');
    if (isNeitherNullNorUndefined(numericDateString)) {
      const format = this.dateService.getDateFormat().replace(/[.:\s]/g, '');
      const date = DateTime.fromFormat(numericDateString, format);
      if (date.isValid) {
        this.form.get('validTo')?.setValue(date.toJSDate());
      }
    }
  }
}
