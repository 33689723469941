/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { UserService } from '@core/services/user.service';
import { SelectItem } from 'primeng/api';
import { VehicleDocument } from '@shared/models/vehicle-document';
import { VehicleFormMode } from 'src/app/vehicles/store';

import { VehicleDocumentsSectionService } from './vehicle-documents-section';
import { FlxValidationMessagesServices } from '@fluxys/gsmart/validation';

@Component({
  selector: 'trkmgr-vehicle-documents-section',
  templateUrl: './vehicle-documents-section.component.html',
  styleUrls: ['../../vehicle-part-dialog.component.scss'],
})
export class VehicleDocumentsSectionComponent implements OnChanges {
  @Input() isValidControl: AbstractControl | null = null;
  @Input() currentDocuments: readonly VehicleDocument[] | null = null;
  @Input() vehiclePartId: number | undefined = undefined;
  @Input() mode: VehicleFormMode | null = null;
  @Output() readonly openDocument = new EventEmitter<number>();
  @Output() readonly documentsChanged = new EventEmitter<VehicleDocument[]>();
  readonly docSection = 'app.modules.vehicles.components.vehicle-dialog.document-section.';
  readonly columns: { field: string; translation: string }[] = [
    { field: 'type', translation: `${this.docSection}columns.type` },
    { field: 'id', translation: `${this.docSection}columns.id` },
    { field: 'validFrom', translation: `${this.docSection}columns.valid-from` },
    { field: 'validTo', translation: `${this.docSection}columns.valid-to` },
    { field: 'validated', translation: `${this.docSection}columns.validated` },
    { field: 'document', translation: `${this.docSection}columns.document` },
  ];
  documents: VehicleDocument[] = [];
  rows: UntypedFormGroup[] = [];
  typeSelectItems: SelectItem<string>[] = [];
  formDialog: UntypedFormGroup = new UntypedFormGroup({});
  showDocumentDialog = false;
  rowIndex = -1;
  titleDialog = '';
  canValidate = false;
  dialogMaxEndDate: Date | undefined;
  maxEndDate: Date | undefined;

  constructor(private readonly documentsService: VehicleDocumentsSectionService, private readonly userService: UserService, private readonly validationMessagesService: FlxValidationMessagesServices) {
    this.userService.loadUserInfo().subscribe((model) => (this.canValidate = model.canReviewVehiclePartDocuments));
    this.validationMessagesService.registerCustomValidationMessages({
      dateBeforeRange: '',
      dateAfterRange: 'app.modules.vehicles.components.vehicle-dialog.document-section.columns.errorValidToADR',
    });
  }

  canDelete = (id: number): boolean => !id;

  ngOnChanges(): void {
    this.typeSelectItems = this.documentsService.createSelectItems();
    this.documents =
      this.mode === 'create' ? [] : this.currentDocuments ? this.currentDocuments.map((entity) => new VehicleDocument(entity)) : [];
    this.createRows();
  }

  replaceorAddClicked(rowIndex = -1): void {
    this.rowIndex = rowIndex;
    this.showDocumentDialog = true;
    this.titleDialog = this.docSection + (rowIndex !== -1 ? 'replace-document' : 'add-document');
    const line = rowIndex === -1 ? undefined : this.documents[rowIndex];
    this.formDialog = this.documentsService.createRow(this.canValidate, line);
    this.dialogMaxEndDate = this.documentsService.setValidToValidation(this.formDialog);
    this.formDialog.get('type')?.valueChanges.subscribe(type => this.dialogMaxEndDate = this.documentsService.setValidToValidation(this.formDialog));
    this.formDialog.get('validFrom')?.valueChanges.subscribe(date => this.dialogMaxEndDate = this.documentsService.setValidToValidation(this.formDialog));
  }

  newFileAdded(event: VehicleDocument): void {
    this.showDocumentDialog = false;
    this.rowIndex !== -1 ? (this.documents[this.rowIndex] = event) : this.documents.push(event);
    this.createRows();
    if (this.rowIndex !== -1) this.documentsService.checkValidated(this.documents, this.rowIndex, this.rows, this.isValidControl);
    this.addVehicleIdAndEmit();
  }

  openDoc(rowIndex: number): void {
    const file = this.documents[rowIndex].document;
    if (file) {
      window.open(window.URL.createObjectURL(file));
    } else if (this.documents[rowIndex].id) {
      this.openDocument.emit(this.documents[rowIndex].id);
    }
  }

  deleteClicked(rowIndex: number): void {
    this.documents.splice(rowIndex, 1);
    this.createRows();
  }

  private createRows(): void {
    this.rows = [];
    this.documents.forEach((doc) => this.rows.push(this.documentsService.createRow(this.canValidate, doc)));

    this.rows.forEach((group, index) => {
      this.maxEndDate = this.documentsService.setValidToValidation(group);
      if (this.mode === 'view') group.get('validated')?.disable();

      group.get('type')?.valueChanges.subscribe((x: 'ADRDocument' | 'DocumentOfConformity') => {
        this.documents[index].documentType = x;
        this.checkAndEmit(index);
        this.maxEndDate = this.documentsService.setValidToValidation(group);
      });
      group.get('validFrom')?.valueChanges.subscribe((x: Date) => {
        this.documents[index].validFrom = new Date(x);
        this.checkAndEmit(index);
        this.maxEndDate = this.documentsService.setValidToValidation(group);
      });
      group.get('validTo')?.valueChanges.subscribe((x: Date) => {
        this.documents[index].validTo = new Date(x);
        this.checkAndEmit(index);
      });
      group.get('validated')?.valueChanges.subscribe((x: boolean) => {
        this.documents[index].validated = x;
        this.addVehicleIdAndEmit();
      });
    });
  }

  private addVehicleIdAndEmit(): void {
    this.documents.forEach((doc) => (doc.vehiclePartId = this.vehiclePartId));
    this.documentsChanged.emit(this.documents);
  }

  private checkAndEmit(index: number): void {
    this.documentsService.checkValidated(this.documents, index, this.rows, this.isValidControl);
    this.addVehicleIdAndEmit();
  }
}
