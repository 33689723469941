import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output } from '@angular/core';
import { FilterMetadata, MenuItem, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';

import { OverviewTableAccessor } from './overview-table-accessor.interface';
import { OVERVIEW_TABLE_ACCESSOR } from './overview-table-accessor.token';

export interface OverviewTableFilter {
  field: string;
  label: string;
  defaultLabel?: string;
  selectedItemsLabel?: string;
  options: SelectItem[] | null;
}

@Component({
  selector: 'trkmgr-overview-table',
  templateUrl: './overview-table.component.html',
  styleUrls: ['./overview-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverviewTableComponent {
  @Input() inlineFilter: OverviewTableFilter | null | undefined;
  @Input() additionalFilter: OverviewTableFilter | null | undefined;
  @Input() dropdownFilters: (OverviewTableFilter | null | undefined)[] | null = null;
  @Input() exportButton = true;

  @Output() readonly clickedExport = new EventEmitter<void>();

  @ContentChild(Table) private readonly _dataTable: Table | undefined;
  @ContentChild(OVERVIEW_TABLE_ACCESSOR) private readonly _tableContent: OverviewTableAccessor | undefined;

  readonly defaultExportCommand: MenuItem = {
    label: 'app.shared.buttons.export',
    icon: 'fas fa-file-export',
    command: () => this.clickedExport.emit(),
  };

  primaryCommand: MenuItem | undefined;
  secondaryCommands: MenuItem[] | undefined;
  searchTextFilter: string | null = null;

  get dataTable(): Table | undefined {
    const dataTable = this._dataTable ?? this._tableContent?.dataTable;
    if (!dataTable) {
      throw new Error('No <p-table></p-table> was provided.');
    }
    return dataTable;
  }

  get canResetFilters(): boolean {
    const dataTable = this.dataTable;
    return (
      !!dataTable &&
      ('global' in dataTable.filters ||
        (this.inlineFilter && this.inlineFilter.field in dataTable.filters) ||
        this.dropdownFilters?.some((ddFilter) => ddFilter && ddFilter.field in dataTable.filters) === true)
    );
  }

  @Input()
  set commands(value: MenuItem[] | null | undefined) {
    if (value && value.length >= 1) {
      [this.primaryCommand, ...this.secondaryCommands] = value;
    }
  }

  inlineFiltersIsSelected(option: SelectItem): boolean {
    const inlineFilter = this.inlineFilter ? this.getFilter(this.inlineFilter.field) : undefined;

    if (inlineFilter === undefined) return option.value === null;

    if (inlineFilter.value) {
      return option.value === inlineFilter.value;
    }

    return false;
  }

  additionalFiltersIsSelected(option: SelectItem): boolean {
    const additionalf = this.additionalFilter ? this.getFilter(this.additionalFilter.field) : undefined;

    if (additionalf === undefined) return option.value === null;

    if (additionalf.value) {
      return option.value === additionalf.value;
    }

    return false;
  }

  getDropdownModel(field: string): string[] | undefined {
    return this.getFilter(field)?.value as string[] | undefined;
  }

  clearFilters(): void {
    this.searchTextFilter = null;
    if (this.dataTable) {
      this.dataTable.filters = {};
      this.dataTable.reset();
    }
  }

  private getFilter(field: string): FilterMetadata | undefined {
    const dataTable = this.dataTable;
    if (field in (dataTable?.filters ?? {})) {
      const filter = dataTable?.filters[field];
      if (!(filter instanceof Array)) {
        return filter;
      }
    }
  }
}
