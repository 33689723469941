import { FeatureFlagsService } from '@core/services/feature-flags.service';
import { TranslocoService } from '@ngneat/transloco';
import { Info, Settings as LuxonSettings } from 'luxon';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';

export class AppInitializerHelper {
  static createFactory(
    featureFlagsService: FeatureFlagsService,
    primeNgConfig: PrimeNGConfig,
    translateService: TranslocoService
  ): () => Observable<unknown> {
    return () => {
      AppInitializerHelper.configureLuxon();
      AppInitializerHelper.configPrimeNg(primeNgConfig, translateService.getActiveLang());
      return featureFlagsService.loadFeatureFlags();
    };
  }

  private static configureLuxon(): void {
    LuxonSettings.defaultZone = 'Europe/Brussels';
  }

  private static configPrimeNg(primeNgConfig: PrimeNGConfig, locale: string): void {
    // activate ripple effect
    primeNgConfig.ripple = true;

    // set translations
    primeNgConfig.setTranslation({
      dayNames: AppInitializerHelper.reorderWeekDays(Info.weekdays('long', { locale })),
      dayNamesShort: AppInitializerHelper.reorderWeekDays(Info.weekdays('short', { locale })),
      dayNamesMin: AppInitializerHelper.reorderWeekDays(Info.weekdays('narrow', { locale })),
      monthNames: Info.months('long', { locale }),
      monthNamesShort: Info.months('short', { locale }),
      clear: 'Clear',
      today: 'Today',
      dateFormat: 'dd.mm.yy',
      firstDayOfWeek: 1,
    });
  }

  private static reorderWeekDays(days: string[]): string[] {
    const [monday, tuesday, wednesday, thursday, friday, saturday, sunday] = days;
    return [sunday, monday, tuesday, wednesday, thursday, friday, saturday];
  }
}
