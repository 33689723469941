<flx-form-field-wrapper
  [label]="hasLabel ? ('app.modules.vehicles.components.vehicle-dialog.document-section.columns.valid-to' | transloco) : ''"
  inputId="validTo" [required]="true" [formGroup]="form" class="p-2">
  <flx-date-picker *ngIf="form.get('type')?.value !== 'ADRDocument'" formControlName="validTo" id="validTo"
    [required]="true" [fluidWidth]="true" dateType="date" [showEot]="true"></flx-date-picker>
  <p-calendar *ngIf="form.get('type')?.value === 'ADRDocument'" formControlName="validTo"
    [maxDate]="maxEndDate ?? defaultDate" [required]="true" [showIcon]="true" icon="fal fa-calendar" dataType="date"
    appendTo="body" [showButtonBar]="true" (onClearClick)="onTomorrowClick()" (onInput)="onInnerInput()" #pcalendar>
  </p-calendar>
  <flx-validation-messages for="validTo"></flx-validation-messages>
  <flx-validation-messages></flx-validation-messages>
</flx-form-field-wrapper>