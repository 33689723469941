<div class="d-flex">
  <div class="section-title align-self-start p-2"
    transloco="app.modules.vehicles.components.vehicle-dialog.vehicle-title.Documents"></div>

  <div>
    <p-table [value]="rows" dataKey="id" editMode="row" styleClass="p-datatable-gridlines"
      tableStyleClass="table-column-options-narrow">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let column of columns">{{ column.translation | transloco }}</th>
          <th *ngIf="mode !== 'view'">&nbsp;</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-row let-i="rowIndex">
        <tr [formGroup]="row" [pEditableRow]="row">
          <td>
            <p-dropdown *ngIf="mode !== 'view'" name="vehiclePartType" [options]="typeSelectItems"
              formControlName="type" [required]="true" appendTo="body" styleClass="w-100"></p-dropdown>
            <flx-validation-messages for="type"></flx-validation-messages>
            <div *ngIf="mode === 'view'">{{ 'app.modules.vehicles.enums.vehicleDocumentType.' + row.get('type')?.value |
              transloco }}</div>
          </td>
          <td>
            <div>{{ row.get('id')?.value }}</div>
          </td>
          <td>
            <flx-date-picker *ngIf="mode !== 'view'" id="validFrom" formControlName="validFrom" [showEot]="true"
              [required]="true" dateType="date" [fluidWidth]="true"></flx-date-picker>
            <flx-validation-messages for="validFrom"></flx-validation-messages>
            <div *ngIf="mode === 'view'">{{row.get('validFrom')?.value | toDate: 'yyyyMMdd' | date: 'dd.MM.yyyy'}}</div>
          </td>
          <td style="width: 270px">
            <trkmgr-calendar-validTo *ngIf="mode !== 'view'" [form]="row" [hasLabel]="false"
              [maxEndDate]="maxEndDate"></trkmgr-calendar-validTo>
            <div *ngIf="mode === 'view'">{{row.get('validTo')?.value | toDate: 'yyyyMMdd' | date: 'dd.MM.yyyy'}}</div>
          </td>
          <td style="text-align: center">
            <p-checkbox id="validated" formControlName="validated" [binary]="true"></p-checkbox>
          </td>
          <td style="text-align: center">
            <p-button (click)="openDoc(i)" styleClass="p-button-tertiary" icon="fa-regular fa-file-pdf"></p-button>
          </td>
          <td style="width: 13%" *ngIf="mode !== 'view'">
            <p-button (click)="replaceorAddClicked(i)" styleClass="p-button-tertiary" icon="fas fa-exchange"></p-button>
            <p-button *ngIf="canDelete(row.get('id').value)" (click)="deleteClicked(i);" styleClass="p-button-tertiary"
              icon="fas fa-trash"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="text-right pr-1 pt-2" *ngIf="mode !== 'view'">
      <p-button (click)="replaceorAddClicked()" styleClass="p-button-secondary"
        [label]="'app.shared.buttons.add' | transloco"></p-button>
    </div>
  </div>
</div>

<div *ngIf="showDocumentDialog">
  <trkmgr-add-vehicle-document [form]="formDialog" [title]="titleDialog" [typeSelectItems]="typeSelectItems"
    [maxEndDate]="dialogMaxEndDate" (cancelClicked)="showDocumentDialog = false;"
    (newFileAdded)="newFileAdded($event)"></trkmgr-add-vehicle-document>
</div>