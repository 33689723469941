import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pluck, tap } from 'rxjs';

import { FeatureFlags } from '../models/feature-flags';

@Injectable({ providedIn: 'root' })
export class FeatureFlagsService {
  readonly flags$: Observable<FeatureFlags>;
  private readonly _flags$: BehaviorSubject<FeatureFlags>;

  constructor(private readonly _http: HttpClient) {
    this._flags$ = new BehaviorSubject<FeatureFlags>({
      driverMustBeAffiliatedToTransporter: false,
      driversSupported: false,
      rejectionReasonsMandatory: false,
      selfLoadingProcessActive: false,
      validateDriverStatusAgainstAppointmentServices: false,
      vehiclePartMandatoryIfStatusBeyondReserved: false,
      vehiclePartMustBeOwnedByTransporter: false,
      bookAndScheduleEnabled: false,
      vehiclePartDocumentUploadEnabled: false,
      recurringAppointmentsEnabled: false,
    });
    this.flags$ = this._flags$.asObservable();
  }

  /**
   * Can be subscribed to refresh the feature flags (is already called once on app init)
   */
  loadFeatureFlags(): Observable<FeatureFlags> {
    return this._http.get<FeatureFlags>('/api/featureflags/get').pipe(tap((f) => this._flags$.next(f)));
  }

  /**
   * Gets an observable from a flag
   * @param flag flag name
   * @returns flag value as observable
   */
  get<T extends keyof FeatureFlags>(flag: T): Observable<FeatureFlags[T]> {
    return this._flags$.pipe(pluck(flag));
  }

  /**
   * Gets a flag instantly
   * @param flag flag name
   * @returns flag value
   */
  getInstantly<T extends keyof FeatureFlags>(flag: T): FeatureFlags[T] {
    return this._flags$.value[flag];
  }
}
