/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FlxValidatedResult } from '@fluxys/gsmart/rule-validation';
import { FlxMessageService } from '@fluxys/primeng';
import { TranslatableMessage } from '@shared/models';
import { showToastOnError } from '@shared/rxjs/operators';
import { addNoValueOption, toUniqueSelectItems } from '@shared/utils';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ObservableSignal } from 'psm5-web';
import { catchError, EMPTY, filter, Observable, switchMap } from 'rxjs';

import { ConfigureDriverCommand } from '../../models/commands';
import {
  AccreditationStatus,
  AffiliationViewModel,
  ApprovalViewModel,
  DocumentType,
  DocumentViewModel,
  TrainingViewModel,
} from '../../models/view-models';
import { CommandsBuilderService, DriverDetailsStoreService, DriverService, DriversManagerService } from '../../services';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'trkmgr-consult-driver-dialog',
  templateUrl: './consult-driver-dialog.component.html',
  providers: [DriversManagerService, DriverDetailsStoreService],
})
export class ConsultDriverDialogComponent implements OnInit {
  readonly context: string = 'consult-driver-dialog';
  readonly form: UntypedFormGroup;
  readonly accreditationStatuses = toUniqueSelectItems<AccreditationStatus>(['Accredited', 'Authorized', 'Unauthorized']);
  readonly documentTypes = toUniqueSelectItems<DocumentType>(['AdrTrainingCertificate', 'DriversLicense', 'IdentityCard']);

  private readonly reloadRequested: ObservableSignal = new ObservableSignal();

  private readonly driverId: number | undefined;

  constructor(
    fb: UntypedFormBuilder,
    readonly store: DriverDetailsStoreService,
    readonly dialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private readonly driverService: DriverService,
    private readonly messageService: FlxMessageService,
    private readonly commandsBuilder: CommandsBuilderService,
    private readonly driversManager: DriversManagerService,
    private readonly transloco: TranslocoService
  ) {
    this.driverId = this.config.data.driverId as number;

    this.form = fb.group({
      firstName: fb.control(null, Validators.required),
      lastName: fb.control(null, Validators.required),
      fullName: fb.control({ value: null, disabled: true }),
      spokenLanguages: fb.control([]),
      phoneNumber: fb.control(null),
    });
  }

  ngOnInit(): void {
    this.reloadRequested.observable
      .pipe(
        filter((_) => this.driverId !== undefined),
        switchMap(() =>
          this.driverService.getConfigureDriverFormData(this.driverId!, this.context).pipe(
            catchError(() => {
              this.messageService.showErrorToast(
                new TranslatableMessage('app.modules.drivers.http-errors.load-driver-error', undefined, {
                  driverId: this.driverId!.toString(),
                })
              );
              return EMPTY;
            })
          )
        )
      )
      .subscribe((vm) => {
        addNoValueOption(vm.transporters, this.transloco, undefined);
        this.store.setState(vm);
      });
  }

  configureDriver(command: ConfigureDriverCommand): void {
    this.driverService
      .configure(command, this.context)
      .pipe(
        showToastOnError<FlxValidatedResult | null>(
          this.messageService,
          'app.modules.drivers.http-errors.configure-driver-error',
          () => null
        )
      )
      .subscribe((result) => {
        if (result !== null) {
          this.reloadRequested.signal();
          this.driversManager.loadDrivers();
        }
      });
  }

  onAddAffiliation(affiliation: AffiliationViewModel): void {
    const command = this.commandsBuilder.buildRegisterAffiliationCommand(affiliation, this.driverId!);
    if (this.form.errors === null) {
      this.runCommandAndReload(
        this.driverService.registerAffiliation(command, this.context),
        'app.modules.drivers.success-notifications.affiliation-registered',
        'app.modules.drivers.http-errors.register-affiliation-error'
      );
    }
  }

  onChangeAffiliation(affiliation: AffiliationViewModel): void {
    const command = this.commandsBuilder.buildConfigureAffiliationCommand(affiliation, this.driverId!);
    if (this.form.errors === null) {
      this.runCommandAndReload(
        this.driverService.configureAffiliation(command, this.context),
        'app.modules.drivers.success-notifications.affiliation-configured',
        'app.modules.drivers.http-errors.configure-affiliation-error'
      );
    }
  }

  onRemoveAffiliation(affiliationId: number): void {
    const command = this.commandsBuilder.buildDeleteAffiliationCommand(affiliationId, this.driverId!);
    this.runCommandAndReload(
      this.driverService.deleteAffiliation(command, this.context),
      'app.modules.drivers.success-notifications.affiliation-deleted',
      'app.modules.drivers.http-errors.delete-affiliation-error'
    );
  }

  onChangeApproval(approval: ApprovalViewModel): void {
    const command = this.commandsBuilder.buildConfigureApprovalCommand(approval, this.driverId!);
    if (this.form.errors === null) {
      this.runCommandAndReload(
        this.driverService.configureApproval(command, this.context),
        'app.modules.drivers.success-notifications.approval-configured',
        'app.modules.drivers.http-errors.configure-approval-error'
      );
    }
  }

  onAddDocument(document: DocumentViewModel): void {
    const command = this.commandsBuilder.buildRegisterDocumentCommand(document, this.driverId!);

    if (this.form.errors === null) {
      this.runCommandAndReload(
        this.driverService.registerDocument(command, this.context),
        'app.modules.drivers.success-notifications.document-registered',
        'app.modules.drivers.http-errors.register-document-error'
      );
    }
  }

  onChangeDocument(document: DocumentViewModel): void {
    const command = this.commandsBuilder.buildConfigureDocumentCommand(document, this.driverId!);

    if (this.form.errors === null) {
      this.runCommandAndReload(
        this.driverService.configureDocument(command, this.context),
        'app.modules.drivers.success-notifications.document-configured',
        'app.modules.drivers.http-errors.configure-document-error'
      );
    }
  }

  onRemoveDocument(documentId: number): void {
    const command = this.commandsBuilder.buildDeleteDocumentCommand(documentId, this.driverId!);
    this.runCommandAndReload(
      this.driverService.deleteDocument(command, this.context),
      'app.modules.drivers.success-notifications.document-deleted',
      'app.modules.drivers.http-errors.delete-document-error'
    );
  }

  onAddTraining(training: TrainingViewModel): void {
    const command = this.commandsBuilder.buildRegisterTrainingCommand(training, this.driverId!);
    if (this.form.errors === null) {
      this.runCommandAndReload(
        this.driverService.registerTraining(command, this.context),
        'app.modules.drivers.success-notifications.training-registered',
        'app.modules.drivers.http-errors.configure-training-error'
      );
    }
  }

  onChangeTraining(training: TrainingViewModel): void {
    const command = this.commandsBuilder.buildConfigureTrainingCommand(training, this.driverId!);
    if (this.form.errors === null) {
      this.runCommandAndReload(
        this.driverService.configureTraining(command, this.context),
        'app.modules.drivers.success-notifications.training-configured',
        'app.modules.drivers.http-errors.configure-training-error'
      );
    }
  }

  onRemoveTraining(trainingId: number): void {
    const command = this.commandsBuilder.buildDeleteTrainingCommand(trainingId, this.driverId!);
    this.runCommandAndReload(
      this.driverService.deleteTraining(command, this.context),
      'app.modules.drivers.success-notifications.training-deleted',
      'app.modules.drivers.http-errors.configure-training-error'
    );
  }

  private runCommandAndReload<TCommandResult extends FlxValidatedResult>(
    commandRequest$: Observable<TCommandResult>,
    successMessage: string,
    errorMessage: string
  ): void {
    commandRequest$.pipe(showToastOnError<TCommandResult | null>(this.messageService, errorMessage, () => null)).subscribe((result) => {
      this.reloadRequested.signal(); // reload in any case

      if (result !== null) {
        if (result.errors && result.errors.length > 0) {
          return;
        }
        this.messageService.showSuccessToast(new TranslatableMessage(successMessage));
      }

      this.driversManager.loadDrivers();
    });
  }
}
