import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '@core/services/user.service';
import { toDate } from '@fluxys/common';
import { UntilDestroy } from '@ngneat/until-destroy';
import { OverviewTableFilter } from '@shared/components';
import { VehicleDocument } from '@shared/models/vehicle-document';
import { toUniqueSelectItems } from '@shared/utils';
import { Table } from 'primeng/table';
import { BlobService } from 'psm5-web';

import { DocumentsService } from '../services/documents.service';

@UntilDestroy()
@Component({
  selector: 'trkmgr-documents-overview',
  templateUrl: './documents-overview.component.html',
})
export class DocumentsOverviewComponent {
  @ViewChild('dt') dt: Table | undefined;
  readonly tableColumns: { key: string; header: string }[] = [
    { key: 'documentType', header: 'type' },
    { key: 'id', header: 'id' },
    { key: 'vehiclePartId', header: 'vehiclePartId' },
    { key: 'owner', header: 'owner' },
    { key: 'validFrom', header: 'validFrom' },
    { key: 'validTo', header: 'validTo' },
    { key: 'validated', header: 'validated' },
    { key: 'document', header: 'document' },
  ];

  readonly inlineFilter: OverviewTableFilter = {
    field: 'documentType',
    label: 'app.modules.vehicle-documents.overview.type',
    options: [
      { label: 'generic.all', value: null },
      { label: 'app.modules.vehicle-documents.types.ADRDocument', value: 'adr' },
      { label: 'app.modules.vehicle-documents.types.DocumentOfConformity', value: 'conformity' },
    ],
  };

  readonly expirationFilter: OverviewTableFilter = {
    field: 'isExpiring',
    label: 'app.modules.vehicle-documents.overview.expiring',
    options: [
      { label: 'generic.all', value: null },
      { label: 'app.modules.vehicle-documents.overview.expiring', value: true },
    ],
  };

  readonly statusFilter: OverviewTableFilter = {
    field: 'validated',
    label: 'app.modules.vehicle-documents.status.title',
    options: [
      { label: 'Validated', value: true },
      { label: 'Not Validated', value: false },
    ],
    defaultLabel: 'app.modules.vehicle-documents.overview.choose',
    selectedItemsLabel: 'app.modules.vehicle-documents.overview.multiple-items-selected',
  };
  dropdownFilters: (OverviewTableFilter | null)[] = [this.statusFilter];

  documents: VehicleDocument[] = [];
  canReviewVehiclePartDocuments = false;

  constructor(
    private readonly service: DocumentsService,
    private readonly blobService: BlobService,
    private readonly userService: UserService
  ) {
    this.userService.loadUserInfo().subscribe((p) => {
      this.canReviewVehiclePartDocuments = p.canReviewVehiclePartDocuments;
      if (!this.canReviewVehiclePartDocuments) {
        this.dt!.sortField = 'validTo';
        this.dt!.sortOrder = 1;
      }
    });

    this.service.getDocuments().subscribe((d) => {
      this.documents = d;
      this.dropdownFilters = [this.setFromOwnerFilter(), this.statusFilter];
    });
  }

  export(): void {
    this.service.exportVehicleDocuments().subscribe((x) => this.blobService.open(x));
  }

  openDocument(document: VehicleDocument): void {
    this.service.openVehicleDocument(document.id).subscribe((x) => window.open(window.URL.createObjectURL(x)));
  }

  validateDocument(validated: boolean, document: VehicleDocument): void {
    document.validated = validated;
    this.service.validateVehicleDocuments(document).subscribe(() => this.service.getDocuments().subscribe((d) => (this.documents = d)));
  }

  private setFromOwnerFilter(): OverviewTableFilter {
    return {
      field: 'owner',
      label: 'app.modules.vehicle-documents.overview.owner',
      options: this.documents ? toUniqueSelectItems(this.documents, ({ owner }) => owner ?? '').filter((item) => item.value) : null,
      defaultLabel: 'app.modules.vehicle-documents.overview.choose',
      selectedItemsLabel: 'app.modules.vehicle-documents.overview.multiple-items-selected',
    };
  }
}
