import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FlxValidators } from '@fluxys/gsmart/validation';
import { FlxWarningDialogService } from '@fluxys/gsmart/warning-dialog';
import { TranslocoService } from '@ngneat/transloco';
import { SelectItem } from 'primeng/api';
import { filter, take } from 'rxjs';
import { VehicleDocument } from '@shared/models/vehicle-document';
import { VehiclesServicesModule } from 'src/app/vehicles/vehicles-services.module';
import { TruckValidators } from '@shared/validators/truck.validators';

@Injectable({ providedIn: VehiclesServicesModule })
export class VehicleDocumentsSectionService {
  constructor(
    private readonly translateService: TranslocoService,
    private readonly fb: UntypedFormBuilder,
    private readonly _warningDialogService: FlxWarningDialogService
  ) { }

  createSelectItems(): SelectItem<string>[] {
    return [
      { value: '', label: '' },
      { value: 'ADRDocument', label: this.translateService.translate(`app.modules.vehicles.enums.vehicleDocumentType.ADRDocument`) },
      {
        value: 'DocumentOfConformity',
        label: this.translateService.translate(`app.modules.vehicles.enums.vehicleDocumentType.DocumentOfConformity`),
      },
    ];
  }

  createRow(canValidate: boolean, doc?: VehicleDocument): UntypedFormGroup {
    const form = this.fb.group(
      {
        type: this.fb.control(doc?.documentType, Validators.required),
        id: this.fb.control(doc?.id),
        validFrom: this.fb.control(doc?.validFrom, Validators.required),
        validTo: this.fb.control(doc?.validTo, Validators.required),
        validated: this.fb.control(doc?.validated),
        documentName: this.fb.control(doc?.documentName, Validators.required),
        document: this.fb.control(doc?.document),
      },
      { validators: FlxValidators.startDateBeforeEndDate('validFrom', 'validTo') }
    );
    canValidate ? form.get('validated')?.enable() : form.get('validated')?.disable();
    return form;
  }

  checkValidated(documents: VehicleDocument[], index: number, rows: UntypedFormGroup[], isValidControl: AbstractControl | null): void {
    const control = rows[index].get('validated');
    if (control?.value === true) {
      control.setValue(false);
      documents[index].validated = false;
      const message = 'app.modules.vehicles.components.vehicle-dialog.document-section.validated-warning';
      this._warningDialogService
        .confirm({ header: 'Warning', message, rejectLabel: 'app.shared.buttons.cancel' })
        .pipe(
          take(1),
          filter((accepted) => accepted)
        )
        .subscribe();
    }
    this.checkIfFormValid(index, rows, isValidControl);
  }

  setValidToValidation(form: UntypedFormGroup): Date | undefined {
    const type: string = form.get('type')?.value;
    const date: Date = form.get('validFrom')?.value;
    let maxEndDate = undefined;
    if (type === 'ADRDocument' && date) {
      maxEndDate = new Date(date);
      maxEndDate.setFullYear(maxEndDate.getFullYear() + 1);
      form.get('validTo')?.setValidators([TruckValidators.inDateRange(date, maxEndDate), Validators.required]);
      form.get('validTo')?.markAsDirty();
    } else {
      form.get('validTo')?.setValidators(Validators.required);
    }
    form.get('validTo')?.updateValueAndValidity();
    return maxEndDate;
  }

  private checkIfFormValid(index: number, rows: UntypedFormGroup[], isValidControl: AbstractControl | null): void {
    // Check if fields are valid, so we can block the save vehicle if one of them isn't
    rows[index].updateValueAndValidity();
    const isFormValid: (boolean | undefined)[] = [rows[index].valid];
    Object.keys(rows[index].controls).forEach((key: string) => {
      const control = rows[index].get(key);
      isFormValid.push(control?.disabled === true || control?.valid === true);
    });
    isValidControl?.setValue(isFormValid.find((value) => value === false) !== undefined ? null : true);
    isValidControl?.updateValueAndValidity();
  }
}
